const ACTIONS = Object.freeze({
  ADD_MY_BEER: "ADD_MY_BEER",
  INIT_MY_BEERS: "INIT_MY_BEERS",
  INIT_BEERS: "INIT_BEERS",
  ADD_BULK_BEERS: "ADD_BULK_BEERS",
  SET_ACTIVE_TAB: "SET_ACTIVE_TAB",
  SET_IS_SHOW_ADD_BEER_MOBEL: "SET_IS_SHOW_ADD_BEER_MOBEL",
  SET_IS_LOADING: "SET_IS_LOADING",
});

export default ACTIONS;
